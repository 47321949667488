exports.onClientEntry = () => {
  const options = "color: #f94e69; font-family: monospace;"
  const linkOptions = "font-family: monospace; font-size: 40px;"
  
  console.log('')
  console.log("%c██████╗ ███████╗██████╗ ███╗   ███╗██╗███╗   ██╗██████╗ ", options)
  console.log("%c██╔══██╗██╔════╝██╔══██╗████╗ ████║██║████╗  ██║██╔══██╗", options)
  console.log("%c██████╔╝█████╗  ██║  ██║██╔████╔██║██║██╔██╗ ██║██║  ██║", options)
  console.log("%c██╔══██╗██╔══╝  ██║  ██║██║╚██╔╝██║██║██║╚██╗██║██║  ██║", options)
  console.log("%c██║  ██║███████╗██████╔╝██║ ╚═╝ ██║██║██║ ╚████║██████╔╝", options)
  console.log("%c╚═╝  ╚═╝╚══════╝╚═════╝ ╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝╚═════╝ ", options)
  console.log('')
  console.log('%chttps://jobs.redmind.se/', linkOptions)
}